<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        Transactions
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.transactions.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <list-fields
                    :fields="fields"
                    :item="item"
                />
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
import Transaction from "../../../models/Transaction";
import ListFields from "../../../components/app/form/ListFields";

export default {
    name: "transactions-show",
    components: {
        AdminDashboardLayout,
        ListFields,
    },
    data: function () {
        return {
            fields: [
                {code: 'reference_id', label: 'Reference ID'},
                {code: 'ext_transaction_id', label: 'External transaction id'},
                {code: 'user_id', label: 'Recipient User id'},
                {code: 'sender_id', label: 'Sender User id'},
                {code: 'from', label: 'Text "from"'},
                {code: 'to', label: 'Text "to"'},
                {code: 'amount', label: 'Amount'},
                {code: 'type', label: 'Type',},
                {code: 'status', label: 'Status'},
                {code: 'deleted_at', label: 'Deleted Time', type: 'date'},
                {code: 'created_at', label: 'Created Time', type: 'date'},
                {code: 'updated_at', label: 'Updated Time', type: 'date'},
            ],
            item: {},
        }
    },
    async mounted() {
        const transactionId = this.$route.params.id
        this.item = await Transaction.find(transactionId)
    },
}
</script>

<style scoped>

</style>
